import { Box, Heading, Container, Text, SimpleGrid, Flex, HStack, VStack } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import React from 'react';
import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import {
  ContactUsBannerWithForm,
  Hero,
  SubHero,
  MainServiceBanner,
  SpecialtyServiceCard,
} from '../../../components/shared';
import { useWindowsAndDoorsService } from '../../../GraphQL/Queries';

const WindowsAndDoors = ({ location }) => {
  const data = useWindowsAndDoorsService();

  const {
    acf_text_block_title,
    acf_image_text_block,
    acf_service_info,
    acf_services_block,
    acf_quote,
  } = data.residentialServiceBy;

  return (
    <Layout location={location}>
      <SEO title="Windows & Doors" />
      <Hero
        image={acf_service_info.background.sourceUrl}
        icon={acf_service_info.icon.sourceUrl}
        heroHeadline={acf_service_info.serviceTitle}
        heroCopy={acf_service_info.serviceShortDescription}
        ctaText="Get a quote"
        fullHeight
      />
      <SubHero heading={acf_text_block_title.title} body={acf_text_block_title.content} />
      <MainServiceBanner
        image={acf_image_text_block.blockList[0].image.sourceUrl}
        title={acf_image_text_block.blockList[0].title}
        noCta
        description={acf_image_text_block.blockList[0].content.replace(/<\/?[^>]+(>|$)/g, '')}
      />
      <Container mt={{ base: '1rem', md: '10rem' }} mb={{ base: '1rem', md: '3rem' }}>
        <SimpleGrid columns={{ base: '1', md: '3' }} spacing={10} mb="5rem">
          <Box px={{ base: '2rem', md: 'unset' }}>
            <Heading
              textStyle="servicePageHeading"
              color="primary"
              maxW={{ base: '100%', md: '450px' }}
              pb="1rem"
              w="70%"
            >
              {acf_image_text_block.blockList[1].title}
            </Heading>
            <Text textStyle="servicePageText">
              {acf_image_text_block.blockList[1].content.replace(/<\/?[^>]+(>|$)/g, '')}
            </Text>
          </Box>
          <SpecialtyServiceCard
            title="IMPACT WINDOWS"
            image="/images/impact-window.jpg"
            description="Our hurricane-impact windows and doors meet the Florida standards for impact, wind resistance and other severe weather conditions."
          />
          <SpecialtyServiceCard
            title="IMPACT DOORS"
            image="/images/impact-door.jpg"
            description="When it comes to impact doors, we have you covered. Our durable impact doors are strong enough to withstand strong winds and impact from incoming storms—and adds a flair of style that’s easy to maintain."
          />
        </SimpleGrid>

        <Box pl={{ base: '2rem', md: 'unset' }}>
          <Heading
            textStyle="servicePageHeading"
            color="primary"
            mb="1rem"
            mt="5rem"
            pb=".5rem"
            borderBottom="4px"
            borderColor="primary"
          >
            Thickness
          </Heading>
          <SimpleGrid
            columns={{ base: '1', md: '3' }}
            spacing={{ base: '10', md: '16' }}
            mb="5rem"
            pt="1rem"
            pr={{ base: '2rem', md: 'unset' }}
          >
            <Box>
              <Flex alignItems="center">
                <Image src="/icons/single-thinkness.svg" />

                <Text color="primary" fontSize="19px" fontWeight="bold" letterSpacing="3.54px" pl="1rem">
                  SINGLE THICKNESS <br />
                  <Text fontSize="16px">(5/16)</Text>
                </Text>
              </Flex>
              <Text pt="1.5rem" textStyle="servicePageText">
                Our single thickness option is affordable and easy-to-maintain but offers the least amount of protection
                from break-ins and serious weather. It also lacks full energy efficiency and noise reduction of all our
                three options.
              </Text>
            </Box>
            <Box>
              <Flex alignItems="center">
                <Image src="/icons/medium-thinkness.svg" />
                <Text color="primary" fontSize="19px" fontWeight="bold" letterSpacing="3.54px" pl="1rem">
                  MEDIUM THICKNESS <br />
                  <Text fontSize="16px">(7/16)</Text>
                </Text>
              </Flex>
              <Text pt="1.5rem" textStyle="servicePageText">
                Our medium thickness option provides half of the protection that full thickness provides. Medium-thick
                windows offer sufficient resistance against potential threats, energy efficiency, and ease of
                maintenance.
              </Text>
            </Box>

            <Box>
              <Flex alignItems="center">
                <Image src="/icons/full-thinkness.svg" />
                <Text color="primary" fontSize="19px" fontWeight="bold" letterSpacing="3.54px" pl="1rem">
                  FULL THICKNESS
                  <br />
                  <Text fontSize="16px">(9/16)</Text>
                </Text>
              </Flex>
              <Text pt="1.5rem" textStyle="servicePageText">
                Our full thickness option for windows is strong, durable, and energy-efficient. It also provides
                enhanced security from your home against intruders and extreme weather.
              </Text>
            </Box>
            <Box h="4px" w="100%" bg="primary" display={{ base: 'block', md: 'none' }} />
          </SimpleGrid>
        </Box>
        <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center" pl={{ base: '2rem', md: 'unset' }}>
          <Heading
            textStyle="servicePageHeading"
            color="primary"
            borderRight={{ base: '0', md: '2px' }}
            borderColor="primary"
            pr={{ md: '2rem' }}
            mr="2rem"
          >
            Comprehensive installation process
          </Heading>
          <Box mt={{ base: '1rem', md: 'unset' }}>
            <Text textStyle="servicePageText" pr={{ base: '2rem', md: 'unset' }}>
              At CH Global Windows & Doors, we deeply care about the integrity and efficiency of your windows and doors.
              That’s why we pay special attention to every aspect of the installation process. We promise to handle any
              repairs and costs as we complete your project to perfection.
            </Text>
            <Text color="secondary" fontWeight="bold" fontSize="21px" mt={{ base: '1rem', md: '1rem' }}>
              NO AFTER REPAIR COST
            </Text>
          </Box>
        </Flex>
      </Container>

      <SimpleGrid
        maxWidth="1800px"
        mx="auto"
        columns={{ base: '1', md: '3' }}
        spacing={{ base: '2', md: '1' }}
        pl={{ base: '2rem', md: 'unset' }}
        mb="5rem"
        pt="1rem"
      >
        {acf_services_block.servicesList.map((service, index) => (
          <Flex
            flexDirection="column"
            h={{ base: '450px', md: '600px' }}
            bg="primary"
            pos="relative"
            justifyContent="flex-end"
          >
            <Image
              src={service.mainImage.sourceUrl}
              objectFit="cover"
              w="100%"
              h={{ base: '275px', md: '400px' }}
              pos="absolute"
              top="0"
            />
            <VStack
              h={{ base: '100%', md: '200px' }}
              zIndex="2"
              p="1.5rem"
              spacing="6"
              alignItems="flex-start"
              justifyContent={{ base: 'space-between', md: 'unset' }}
              mb={{ base: '1rem', md: 'unset' }}
            >
              <HStack alignItems="center" spacing="1rem">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  bg="secondary"
                  color="white"
                  fontWeight="black"
                  p="3"
                  borderRadius="100%"
                  h="54px"
                  w="54px"
                  fontSize="38.4px"
                >
                  <Heading>{index + 1}</Heading>
                </Flex>
                <Text color="white" fontSize="18px" fontWeight="bold">
                  {service.serviceTitle}
                </Text>
              </HStack>
              <Text fontSize="16px" color="white" lineHeight="1.38" mt="0">
                {service.serviceDescription}
              </Text>
            </VStack>
          </Flex>
        ))}
      </SimpleGrid>
      <Box
        maxWidth="1600px"
        h={{ base: '650px', md: '600px' }}
        pr="2rem"
        pos="relative"
        m={{ base: '0 0 1rem 2rem', md: '0 auto 10rem auto' }}
      >
        <Image
          zIndex="0"
          w="922px"
          h={{ base: '181px', md: '573px' }}
          src="/images/windows-client.jpg"
          pos="absolute"
          right="0"
          top="0"
          objectFit="cover"
        />
        <Box
          width={{ base: '100%', md: '803px' }}
          h={{ base: 'auto', md: 'auto' }}
          zIndex="2"
          pos="absolute"
          top={{ base: '10rem', md: '5rem' }}
          left="0"
          filter="drop-shadow(0px 10px 20px rgba(50, 50, 0, 0.1))"
        >
          <Box
            w="100%"
            h="100%"
            clipPath={{ base: '', md: 'polygon(93% 0, 100% 13%, 100% 100%, 0 100%, 0 0)' }}
            bg="white"
            py="3rem"
            px={{ base: '2rem', md: '11rem' }}
          >
            <Text textStyle="servicePageText">
              We work with a careful selection of the best vendors in the industry to provide you with flawless impact
              protection including:
            </Text>
            <VStack alignItems="flex-start" mt="2rem" display={{ base: 'none', md: 'flex' }}>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">EuroCraft</Text>
              </HStack>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">PGT Custom Windows + Doors</Text>
              </HStack>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">CGI Impact Resistant Windows & Doors</Text>
              </HStack>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">WinDoor High-Performance Windows & Doors</Text>
              </HStack>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">Eco Windows Systems</Text>
              </HStack>
              <HStack alignItems="center">
                <Box h="12px" w="12px" rounded="100%" border="3px" borderColor="secondary" borderStyle="solid" />
                <Text textStyle="servicePageText">Mr. Glass Windows & Doors Manufacturing</Text>
              </HStack>
            </VStack>
            <SimpleGrid columns={{ base: '2', md: '3' }} spacing={2} mt="2rem">
              <Image src="/images/client-eurocraft.png" w="130px" h="75px" objectFit="contain" transform="scale(0.8)" />
              <Image src="/images/client-pgt.png" w="130px" h="75px" objectFit="contain" transform="scale(0.6)" />
              <Image src="/images/client-cgi.png" w="130px" h="75px" objectFit="contain" transform="scale(0.8)" />
              <Image src="/images/client-windoor.png" w="130px" h="75px" objectFit="contain" transform="scale(0.8)" />
              <Image src="/images/client-eco.png" w="130px" h="75px" objectFit="contain" transform="scale(0.6)" />
              <Image src="/images/mrglass.png" w="130px" h="75px" objectFit="contain" transform="scale(0.9)" />
            </SimpleGrid>
          </Box>
        </Box>
      </Box>

      {/* <ProjectsSection residential /> */}
      <Box mb={{ md: '15rem' }}></Box>

      <ContactUsBannerWithForm
        title={acf_quote.titleQuoteSection}
        image={acf_quote.backgroundImage.sourceUrl}
        description={acf_quote.descriptionQuote}
      />
    </Layout>
  );
};

export default WindowsAndDoors;
